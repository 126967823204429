.footer-container {
  color: white;
  text-align: left;
  padding: 2% 10%;
  background-color: rgba(34, 53, 139);
}

h1 {
  margin-bottom: 20px;
  text-decoration: underline;;
}

span {
  margin-left: 12px;
  font-size: 1.1em;
}

p {
  margin: 10px;
}

.copyright-text {
  text-align: right;
}

.copyright-text:hover {
  color: #FF471F;
}