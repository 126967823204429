.contact-container {
  margin-top: 2%;
  color: rgba(34, 53, 139);
  text-align: left;
  padding: 2% 10%;
}

h1 {
  margin-bottom: 20px;
  text-decoration: underline;
}


.email {
  margin-left: 20px;
  font-size: 1.2em;
  text-decoration: underline;
}

.indent {
  margin-left: 5%;
}

.content-container {
  margin-top: 3%;
  padding: 2%;
}

.btn-container {
  text-align: right;
}