@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@600&display=swap');

body {
  font-family: 'Roboto Slab', serif;
    /* background: rgb(2,0,36); */
  /* background: linear-gradient(to bottom right, rgba(2,0,36,1) 0%, rgba(42,63,158,1) 85%, rgba(255,80,0,0.8048261540944502) 100%); */
  color: rgba(34, 53, 139);
}
.App {
  text-align: center;
}

p {
  margin: 3px;
}

.headline-text {
  font-size: 1.25em;
  text-decoration: underline;
}
.recruitment-text {
  font-size: 15px;
}
.mail-link {
  color: #fff;
}

.ml-auto {
  margin-left: auto;
}
.App-logo {
  /* width: 25%; */
  max-width: 250px;
}

.app-header {
  background-color: rgba(34, 53, 139);
  padding: 15px;
}

.carousel {
  min-height: 95vh;
}
.carousel-item {
  max-height: 95vh;
}

.carouel img {
  height: 100%;
}

.split-header {
  padding: 0;
}

a {
  text-decoration: none;
}

.icon {
  width: 3%;
  max-width: 64px;
  min-width: 32px;
  margin: 7px;
}

.film {
  width: 100%;
  max-width: 128px;
  min-width: 32px;
  margin: 7px;
}

.nav-link {
  text-decoration: none !important;
  color:  white;
  font-weight: 600;
}

.brand {
  width: 100%;
  max-width: 128px;
  min-width: 32px;
}

.nav-link:hover {
  color: #FF471F;
}

.get-in-touch-btn {
  padding: 7px 30px;
}

.top-nav {
  background-color: rgba(255, 255, 255, 0) !important;
  transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -webkit-transition: background-color 0.5s ease-in-out;
}

.scrolled-nav {
  opacity: 1;
  /* background-color: rgba(255, 255, 255, 0.8) !important; */
  background-color: rgba(34, 53, 139);
  transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -webkit-transition: background-color 0.5s ease-in-out;
}

.headline-container {
  height: 100%;
  display: flex;
  /* align-items: center; */
  text-align: right;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 10%;
  padding-top: 15vh;
}

.headline-container p {
  font-size: 1.2em;
}
.supporting-images-container {
  margin-top: 30px;
  display: flex;
  align-items: left;
  justify-content: space-evenly;
}

.supporting-images-container img {
  width: 80%;
  max-width: 150px;
}

.title-container {
  margin-top: 20px;
}

.move-count-container {
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 15px;
}

.show {
  /* background-color: rgba(255, 255, 255, 0.8); */
}

.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.move-count-container span {
  margin-left: 10px;
  margin-right: 5px;
  color: #FF471F;
}
.services-container {
  background-color: rgba(34, 53, 139);
}

.btn-primary {
  background-color: rgba(34, 53, 139); 
}

.productions-container {
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-secondary {
  /* background-color: #FF471F; */
  /* color: rgba(34, 53, 139);  */
}